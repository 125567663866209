import * as React from 'react';
import Helmet from 'react-helmet';

import { Intro } from 'components/intro/Intro';

// tslint:disable no-default-export
export default () => (
  <>
    <Helmet title="Consulting" />

    <Intro>
      <p>Every product, team, and company is different. <br/><a href="mailto:emily@tinkertaylor.dev">Get in contact</a> to talk about software development consulting and training.</p><br/>
      <p>After our initial conversation, I'll create a set of recommendations for next actions.</p>
    </Intro>
  </>
);
